import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'

import getFirebase from './firebase/firebase'
import SEO from './seo'
import FirebaseContext from './firebase/context'
import withAuthentication from './Session/withAuthentication'
import './css/css.css'
import Header from './header'
import Footer from './Footer'

class Layout extends Component {
  state = {
    firebase: null,
  }

  componentDidMount() {
    const app = import('firebase/app')
    const auth = import('firebase/auth')
    const database = import('firebase/database')
    Promise.all([app, auth, database]).then(values => {
      const firebase = getFirebase(values[0])
      this.setState({ firebase })
    })
  }

  render() {
    const { firebase } = this.state
    return (
      <FirebaseContext.Provider value={firebase}>
        <AppWithAuthentication {...this.props} />
      </FirebaseContext.Provider>
    )
  }
}

const AppWithAuthentication = withAuthentication(props => (
  <Fragment>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        const { children } = props
        return (
          <article className="sans-serif">
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
            <Helmet>
              {`
                <script type="text/javascript">
                  gtag('config', 'AW-1008008291')
                </script>
              `}
            </Helmet>
            <Header siteTitle={data.site.siteMetadata.title} />
            <main id={props.id}>{children}</main>
            <Footer />
          </article>
        )
      }}
    />
  </Fragment>
))

export default injectIntl(Layout)
