// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-business-js": () => import("/opt/build/repo/src/templates/business.js" /* webpackChunkName: "component---src-templates-business-js" */),
  "component---src-templates-tribute-js": () => import("/opt/build/repo/src/templates/tribute.js" /* webpackChunkName: "component---src-templates-tribute-js" */),
  "component---src-templates-memorial-js": () => import("/opt/build/repo/src/templates/memorial.js" /* webpackChunkName: "component---src-templates-memorial-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-js": () => import("/opt/build/repo/src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-confirmation-js": () => import("/opt/build/repo/src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-create-memorial-js": () => import("/opt/build/repo/src/pages/create-memorial.js" /* webpackChunkName: "component---src-pages-create-memorial-js" */),
  "component---src-pages-create-tribute-js": () => import("/opt/build/repo/src/pages/create-tribute.js" /* webpackChunkName: "component---src-pages-create-tribute-js" */),
  "component---src-pages-edit-memorial-js": () => import("/opt/build/repo/src/pages/edit-memorial.js" /* webpackChunkName: "component---src-pages-edit-memorial-js" */),
  "component---src-pages-edit-tribute-js": () => import("/opt/build/repo/src/pages/edit-tribute.js" /* webpackChunkName: "component---src-pages-edit-tribute-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-account-js": () => import("/opt/build/repo/src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("/opt/build/repo/src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-signin-js": () => import("/opt/build/repo/src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-termos-de-servico-js": () => import("/opt/build/repo/src/pages/termos-de-servico.js" /* webpackChunkName: "component---src-pages-termos-de-servico-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

