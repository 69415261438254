import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/immortalis.svg'
import NavBar from './navBar'

const Header = ({ siteTitle }) => (
  <header className="bg-light-blue" style={{ backgroundColor: '#5dc6e4c7' }}>
    <div className="mw8-ns center pa2-ns flex justify-between">
      <h1 className="ma0">
        <Link to="/">
          <img alt="Immortalis Logo" src={logo} className="w4 w5-ns ml2 mt1-ns nt1" />
        </Link>
      </h1>
      <NavBar />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
