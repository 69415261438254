import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'gatsby'

import * as routes from '../../constants/routes'

const Footer = ({ intl: { formatMessage } }) => {
  const date = new Date().getFullYear()
  return (
    <footer className="bg-navy tc white w-100 f7">
      <div className="center mw8-ns pa3 flex flex-wrap justify-between flex-column-reverse flex-row-ns">
        <div className="w-100 w-50-ns tc tl-ns lh-copy">
          {`Copyright © 2018-${date} Immortalis.`}
          <br className="db dn-ns" />
          {formatMessage({ id: 'common:all_rights_reserved' })}
        </div>
        <div className="w-100 w-50-ns tc tr-ns lh-copy mb3 mb0-ns">
          <Link to={routes.TERMS_OF_USE} className="f7 white mr2">
            {formatMessage({ id: 'common:terms_of_service' })}
          </Link>
          <Link to={routes.PRIVACY_POLICY} className="f7 white mr2">
            {formatMessage({ id: 'common:privacy_policy' })}
          </Link>
          <Link to={routes.B2B} className="f7 white mr2">
            {formatMessage({ id: 'common:for_businesses' })}
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default injectIntl(Footer)
