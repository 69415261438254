import React from 'react'
import { Link } from 'gatsby'

import { withFirebase } from '../firebase/context'
import * as routes from '../../constants/routes'

const SignOutButton = props => {
  const { displayName, firebase, photoURL } = props
  return (
    <li className="ma0 mr3-m f7 h2 flex items-center">
      <div className="flex items-center near-black fw6 pointer hide-child relative">
        {photoURL && (
          <img alt={displayName} className="w1 h1 dn db-ns br-pill mr1" src={photoURL} />
        )}
        <span className="dn db-l">{displayName ? displayName.split(' ')[0] : ''}</span>
        <span className="db dn-ns">
          <Link to={routes.MY_ACCOUNT} onClick={() => document.getElementById('nav').click()}>
            Minha Conta
          </Link>
        </span>
        <span className="material-icons f6 dn db-ns">expand_more</span>
        <div className="absolute right-0 top-0 z-max child">
          <div className="mt4 relative">
            <div className="context">
              <ul className="list ma0 pa0 w4 bg-white br3 cf">
                <li className="bg-white hover-bg-light-gray w-100 br2">
                  <Link to={routes.MY_ACCOUNT} className="f7 pointer black db w-100 pa2">
                    Minha Conta
                  </Link>
                </li>
                <li className="bg-white hover-bg-light-gray w-100 br2">
                  <a
                    href={routes.LANDING}
                    className="f7 pointer black db w-100 pa2"
                    onClick={firebase.doSignOut}
                  >
                    Sair
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default withFirebase(SignOutButton)
