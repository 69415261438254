import '@firebase/storage'
import '@firebase/firestore'

const prodConfig = {
  apiKey: 'AIzaSyAnQ0N7qVJ-XOJPgk44QVeb3Vm-tWGwdhM',
  authDomain: 'immortalis.firebaseapp.com',
  databaseURL: 'https://immortalis.firebaseio.com',
  projectId: 'immortalis',
  storageBucket: 'immortalis.appspot.com',
  messagingSenderId: '112969098990',
}

const devConfig = {
  apiKey: 'AIzaSyAnQ0N7qVJ-XOJPgk44QVeb3Vm-tWGwdhM',
  authDomain: 'immortalis.firebaseapp.com',
  databaseURL: 'https://immortalis.firebaseio.com',
  projectId: 'immortalis',
  storageBucket: 'immortalis.appspot.com',
  messagingSenderId: '112969098990',
}

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig

class Firebase {
  constructor(app) {
    if (!app) {
      // eslint-disable-next-line no-param-reassign
      app = import('firebase/app')
    }
    app.initializeApp(config)
    this.db = app.database()
    this.auth = app.auth()
    this.firestore = app.firestore()
    const firestoreSettings = { timestampsInSnapshots: true }
    this.firestore.settings(firestoreSettings)
    this.storage = app.storage()

    this.facebookProvider = new app.auth.FacebookAuthProvider()
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider)

  doSignOut = () => {
    sessionStorage.removeItem('immortalis-user')
    this.auth.signOut()
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  // *** User API ***

  doCreateUser = (id, username, email) =>
    this.db.ref(`Users/${id}`).set({
      username,
      email,
    })

  onceGetUsers = () => this.db.ref('Users').once('value')

  // user = uid => this.db.ref(`Users/${uid}`)

  user = person =>
    new Promise(resolve => {
      let newDoc = {}
      const docRef = this.firestore.collection('Users').doc(person.uid)
      this.firestore.runTransaction(t =>
        t
          .get(docRef)
          .then(doc => {
            const companies = doc.get('companies') ? doc.get('companies') : []
            const roles = doc.get('roles') ? doc.get('roles') : []
            newDoc = {
              companies,
              email: person.email,
              name: person.name,
              roles,
              socialId: person.socialId,
            }
            t.set(docRef, newDoc, { merge: true })
            const companiesStringArray = []
            companies.map(company => companiesStringArray.push(company.id))
            newDoc.companies = companiesStringArray
          })
          .then(result => {
            window.sessionStorage.setItem('immortalis-user', JSON.stringify(newDoc))
            resolve(result)
          })
      )
    })

  // users = () => this.db.ref('Users')
}

let firebase

function getFirebase(app, auth, database, firestore, storage) {
  if (firebase) {
    return firebase
  }
  firebase = new Firebase(app, auth, database, firestore, storage)
  return firebase
}

export default getFirebase
