/* eslint-disable import/prefer-default-export */
import React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import ptData from 'react-intl/locale-data/pt'

import Layout from './src/components/layout'
import { messages, defaultLocale } from './src/i18n/i18n'

addLocaleData([...ptData])

export const wrapPageElement = ({ props, element }) => {
  const { pageContext } = props
  const { locale, langKey } = pageContext
  const pageLocale = locale || langKey || defaultLocale
  return (
    <IntlProvider
      locale={pageLocale}
      messages={messages[pageLocale]}
      textComponent={React.Fragment}
    >
      <Layout locale={pageLocale} {...props}>
        {element}
      </Layout>
    </IntlProvider>
  )
}
