export const CONTACT = '/contact/'
export const CREATE_MEMORIAL = '/create-memorial/'
export const CREATE_TRIBUTE = '/create-tribute/'
export const B2B = '/business/'
export const LANDING = '/'
export const MY_ACCOUNT = '/my-account/'
export const PASSWORD_FORGET = '/pw-forget/'
export const PRIVACY_POLICY = '/politica-de-privacidade/'
export const SIGN_IN = '/signin/'
export const SIGN_UP = '/signup/'
export const TERMS_OF_USE = '/termos-de-servico/'
