import React from 'react'
import { Link } from 'gatsby'
import Scrollchor from 'react-scrollchor'

import * as routes from '../constants/routes'
import AuthUserContext from './Session/AuthUserContext'
import SignOutButton from './SignOut'

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser => (authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />)}
  </AuthUserContext.Consumer>
)

const DefaultLinks = () => [
  <li key="item-home" className="ma0 mr3-ns h2 flex items-center">
    <Link to={routes.LANDING} className="fl f7 white hover-black h-100 flex items-center">
      Início
    </Link>
  </li>,
  <li key="item-tribute" className="ma0 mr3-ns h2 flex items-center">
    <Link
      to={routes.CREATE_TRIBUTE}
      className="fl f7 white hover-black h-100 flex items-center"
      onClick={() => {
        document.getElementById('nav').checked = true
        document.getElementById('nav').click()
      }}
    >
      Criar Tributo
    </Link>
  </li>,
  <li key="item-memorial" className="ma0 mr3-ns h2 flex items-center">
    <Link
      to={routes.CREATE_MEMORIAL}
      className="fl f7 white hover-black h-100 flex items-center"
      onClick={() => {
        document.getElementById('nav').checked = true
        document.getElementById('nav').click()
      }}
    >
      Criar Memorial
    </Link>
  </li>,
  <li key="item-contact" className="ma0 mr3-ns h2 flex items-center">
    {typeof window !== 'undefined' && window.location.pathname === '/' && (
      <Scrollchor
        to="#contact"
        className="fl f7 white hover-black h-100 flex items-center"
        afterAnimate={() => {
          document.getElementById('nav').checked = false
        }}
      >
        Contato
      </Scrollchor>
    )}
    {typeof window !== 'undefined' && window.location.pathname !== '/' && (
      <Link
        to={`${routes.LANDING}#contact`}
        className="fl f7 white hover-black h-100 flex items-center"
        onClick={() => {
          document.getElementById('nav').checked = true
          document.getElementById('nav').click()
        }}
      >
        Contato
      </Link>
    )}
  </li>,
]

const NavigationAuth = props => {
  const { authUser } = props
  const { userName, photoURL } = authUser
  return (
    <nav id="site-navbar" className="pa0 flex items-center">
      <input
        type="checkbox"
        id="nav"
        className="hidden"
        onClick={e => {
          e.target.checked
            ? document.body.classList.add('overflow-hidden', 'menu-open')
            : document.body.classList.remove('overflow-hidden', 'menu-open')
        }}
      />
      <label htmlFor="nav" className="nav-open">
        <i />
        <i />
        <i />
      </label>
      <div className="nav-container">
        <ul className="pr3-m list ml0 mv0">
          <DefaultLinks />
          <SignOutButton displayName={userName} photoURL={photoURL} />
        </ul>
      </div>
    </nav>
  )
}

const NavigationNonAuth = () => (
  <nav id="site-navbar" className="pa0 flex items-center">
    <input type="checkbox" id="nav" className="hidden" />
    <label htmlFor="nav" className="nav-open">
      <i />
      <i />
      <i />
    </label>
    <div className="nav-container">
      <ul className="pr3-m non-auth list ml0 mv0">
        <DefaultLinks />
        <li key="item-sign-in" className="ma0 mr3-ns h2 flex items-center">
          <Link to={routes.SIGN_IN} className="fl f7 white hover-black h-100 flex items-center">
            Acessar Conta
          </Link>
        </li>
      </ul>
    </div>
  </nav>
)

export default Navigation
