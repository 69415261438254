import React from 'react'

import AuthUserContext from './AuthUserContext'
import { withFirebase } from '../firebase/context'

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        initFirebase: false,
        authUser: null,
      }
    }

    componentDidMount() {
      this.firebaseInit()
    }

    componentDidUpdate() {
      this.firebaseInit()
    }

    firebaseInit = () => {
      const { firebase } = this.props
      const { initFirebase } = this.state
      const $this = this
      if (firebase && !initFirebase) {
        firebase.auth.onAuthStateChanged(authUser => {
          const returnedUser = authUser
          if (authUser) {
            const { firestore } = firebase
            firestore
              .collection('Users')
              .doc(returnedUser.uid)
              .get()
              .then(userDoc => {
                if (userDoc.data()) {
                  returnedUser.roles = userDoc.data().roles
                  returnedUser.userName = userDoc.data().name
                  $this.setState(() => ({ authUser: returnedUser, initFirebase: true }))
                } else {
                  $this.setState(() => ({ initFirebase: true }))
                }
              })
          } else {
            $this.setState(() => ({
              authUser: null,
              initFirebase: true,
            }))
          }
        })
      }
    }

    render() {
      const { authUser } = this.state
      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
